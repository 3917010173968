<template>
    <div>
        <h2 class="m-title">
            <div class="txt"></div>
            <div class="btn-area">
                <v-btn color="primary" @click="newTableRow()">新稱</v-btn>
            </div>
        </h2>
        <div id="real-table"></div>
    </div>
</template>

<script>
import { mdiPencil } from '@mdi/js';
import axios from 'axios';
import config from '@/config.js';

var tabledata = [];
var table;
export default{
    setup() {
        return {
        
        // Icons
        icons: {
            mdiPencil,
        },
        }
    },
    name: 'Rule',
    data: () => ({
        
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(10)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){
        var self = this;
        $(function() {
            $(".nav_mainTitle").text("規則管理");


            var optIconFunction = function(cell, formatterParams){ 
                    return "<div class='btn-group text-center'><a class='btn btn-icon'><span class='icon edit'></span></a></div>"
                };

                 var optIconFunction2 = function(cell, formatterParams){ 
                    return "<div class='btn-group text-center'><a class='btn btn-icon'><span class='icon delete'></span></a></div>"
                };

            const apiurl=config.geturl(config.API.inforule,{});
            axios.get(apiurl).then(response => {
                if(response.data !== null && response.data !== ""){
                    tabledata = response.data;
                    //console.log(tabledata);
                    table = new Tabulator("#real-table", {
                        height:"auto",
                        locale:"en-gb",
                        langs:{
                            "en-gb":{
                                "pagination":{
                                    "page_size":"頁數", //label for the page size select element
                                    "first":"第一頁", //text for the first page button
                                    "first_title":"第一頁", //tooltip text for the first page button
                                    "last":"最後一頁",
                                    "last_title":"最後一頁",
                                    "prev":"上一頁",
                                    "prev_title":"上一頁",
                                    "next":"下一頁",
                                    "next_title":"下一頁",

                                },
                            }
                        },
                        data:tabledata,           //load row data from array
                        layout:"fitColumns",      //fit columns to width of table
                        responsiveLayout:"hide",  //hide columns that dont fit on the table
                        tooltips:true,            //show tool tips on cells
                        addRowPos:"top",          //when adding a new row, add it to the top of the table
                        history:true,             //allow undo and redo actions on the table
                        pagination:"local",       //paginate the data
                        paginationSize:50,         //allow 7 rows per page of data
                        paginationSizeSelector:true,
                        //paginationCounter:"rows", //display count of paginated rows in footer
                        movableColumns:true,      //allow column order to be changed
                        resizableRows:true,       //allow row order to be changed
                        columns:[
                            {title:"id", field:"no", sorter:"string",height:47},
                            {title:"名字", field:"name", sorter:"string",height:47, editor:"input"},
                            {title:"群組", field:"subset", sorter:"string",height:47, editor:"input"},
                            {title:"測項", field:"sensor", sorter:"string",height:47, editor:"select", editorParams:{
                                    "PM2.5":"PM2.5",
                                    "TVOC":"TVOC",
                                    "PM10":"PM10",
                                }},
                            {title:"等級", field:"level", sorter:"string", editor:"input"},
                            {title:"方法", field:"methods", sorter:"string", editor:"select", editorParams:{
                                    "上升幅度":"上升幅度",
                                    "multipleSd":"標準差",
                                    "門檻":"門檻",
                                }},
                            {title:"動量", field:"momentum", sorter:"string", editor:"input"},
                            {title:"門檻值", field:"threshold", sorter:"string", editor:"input"},
                            {title:"延遲時間", field:"delay_time", sorter:"string", editor:"input"},
                            {title:"平穩時間", field:"expect_length", sorter:"string", editor:"input"},
                            {title:"容錯率", field:"tolerate", sorter:"string", editor:"input"},
                            {title:"累積次數", field:"accumulated_count", sorter:"string", editor:"input"},
                            {title:"累積時間", field:"accumulated_interval", sorter:"string", editor:"input"},
                            {title:"是否靜音", field:"silent", sorter:"string", editor:"select", editorParams:{
                                    "1":"是",
                                    "0":"否",
                                }},
                            {title:"是否啟用", field:"enabled", sorter:"string", editor:"select", editorParams:{
                                    "1":"是",
                                    "0":"否",
                                }},
                            {title:"新增/修改", field:"opt",tooltip:false,tooltipsHeader:false,formatter:optIconFunction,headerSort:false, cellClick:function(e, cell){
                                var re = cell.getRow(cell);
                                var allData = re.getData();
                                self.setTrData(allData);
                                //console.log(allData);
                            }},
                            {title:"刪除", field:"opt2",tooltip:false,tooltipsHeader:false,formatter:optIconFunction2,headerSort:false, cellClick:function(e, cell){
                                var re = cell.getRow(cell);
                                var allData = re.getData();
                                self.delTrData(allData);
                            }},
                        ],
                        rowClick:function(e, row){
                            _curRow = row;
                        },
                        //autoColumns:true,
                    });
                }

            });
            


        })
    },
    methods:{
        newTableRow(){
            table.addData([{no:null,name:"",subset:"",sensor:"",level:1,methods:"multipleSd",momentum:null,threshold:null,delay_time:null,expect_length:null,tolerate:null,silent:null,enabled:null,deleted:null,accumulated_count:null,accumulated_interval:null}], true)
                .then(function(rows){
                    //rows - array of the row components for the rows updated or added

                    //run code after data has been updated
                })
                .catch(function(error){
                    //handle error updating data
                });
        },
        setTrData(obj_json){
            
            //檢查數字
            if($.isNumeric(obj_json.level) && $.isNumeric(obj_json.momentum) && $.isNumeric(obj_json.threshold)
             && $.isNumeric(obj_json.delay_time) && $.isNumeric(obj_json.expect_length) && $.isNumeric(obj_json.tolerate)
              && $.isNumeric(obj_json.accumulated_count) && $.isNumeric(obj_json.accumulated_interval)){
                var parseData=obj_json;
                const apiurl=config.geturl(config.API.setTableRules,{});
                //console.log(parseData);
                axios.post(apiurl, parseData)
                        .then((response) => {
                            if (response.data !== "" && response.data.status == "Y") {
                                //新增/修改成功
                                const apiurl=config.geturl(config.API.inforule,{});
                                axios.get(apiurl).then(response => {
                                    if(response.data !== null && response.data !== ""){
                                        tabledata = response.data;
                                        table.replaceData(tabledata);
                                    }
                                });
                                
                            } else {
                                
                            }
                            alert(response.data.msg);

                        })

              }else{
                if($.isNumeric(obj_json.level)) alert("等級請輸入數字");
                if($.isNumeric(obj_json.momentum)) alert("動量請輸入數字");
                if($.isNumeric(obj_json.threshold)) alert("門檻值請輸入數字");
                if($.isNumeric(obj_json.delay_time)) alert("延遲時間請輸入數字");
                if($.isNumeric(obj_json.expect_length)) alert("平穩時間請輸入數字");
                if($.isNumeric(obj_json.tolerate)) alert("容錯率請輸入數字");
                if($.isNumeric(obj_json.accumulated_count)) alert("累積次數請輸入數字");
                if($.isNumeric(obj_json.accumulated_interval)) alert("累積時間請輸入數字");
              }
            
        },
        delTrData(obj_json){
            var parseData=obj_json;
                const apiurl=config.geturl(config.API.delTableRules,{});
                //console.log(parseData);
                axios.post(apiurl, parseData)
                        .then((response) => {
                            if (response.data !== "" && response.data.status == "Y") {
                                //刪除成功
                                const apiurl=config.geturl(config.API.inforule,{});
                                axios.get(apiurl).then(response => {
                                    if(response.data !== null && response.data !== ""){
                                        tabledata = response.data;
                                        table.replaceData(tabledata);
                                    }
                                });
                                
                            } else {
                                
                            }
                            alert(response.data.msg);

                        })
        }
    }
}

function editData(objdata){
    console.log("edit" + objdata);
}

</script>